import { deleteItem as arrayDeleteItem } from "../helpers/ArrayHelper";

export default {
  name: 'assets',

  hooks: {
    app: {
      init() {
        // Manage loaded JS before app was ready to handle it.
        this.bootJsBuffer.forEach((group) => this.assets.setLoadedJs(group));

        // Should not be used anymore.
        this.bootJsBuffer = null;

        return 'complete';
      },

      loadAppData(data, registry, next) {
        // Load CSS.
        this.assets.cssLoadAssets(data.assets.css, () => {
          // Load JS.
          this.assets.jsLoadAssets(data.assets.js, () => {
            // Load other data through mixins.
            next();
          });
        });

        return 'stop';
      },
    },
  },

  methods: {
    app: {
      loadedJs: {},
      loadQueues: [],
      loadedCss: {},

      cssLoadAssets(items, callback) {
        let count = 0;
        let complete = () => {
          count === 0 && callback && callback();
        };

        items.forEach((item) => {
          // Prevent double loads.
          if (!this.assets.loadedCss[item.path] && !item.loaded) {
            let el = this.assets.addStyle(item.path);
            el.setAttribute('media', item.media);
          }

          this.assets.loadedCss[item.path] = true;
          item.loaded = true;
        });

        // If list is empty.
        complete();
      },

      jsLoadAssets(items, callback) {
        let queue = {
          items: items,
          callback: callback,
        };

        // Fill up already loaded items.
        items.forEach((item) => {
          if (this.assets.loadedJs[item.group]) {
            item.loaded = true;
          }
        });

        this.assets.loadQueues.push(queue);

        this.assets.loadQueueNext(queue);
      },

      loadQueueNext(queue) {
        for (let item of queue.items) {
          if (!item.loaded) {
            this.assets.addScript(item.path);
            return;
          }
        }

        // No more name set to "false".
        arrayDeleteItem(this.assets.loadQueues, queue);

        queue.callback && queue.callback();
      },

      setLoadedJs(group) {
        this.assets.loadedJs[group] = true;
        this.assets.loadQueues.forEach((queue) => {
          queue.items.forEach((item) => {
            if (item.group === group) {
              item.loaded = true;
            }
          });

          this.assets.loadQueueNext(queue);
        });
      },

      addScript(src) {
        let el = document.createElement('script');
        el.src = src;
        document.head.appendChild(el);
        return el;
      },

      addStyle(href) {
        let el = this.assets.createStyleLinkElement();
        el.href = href;
        document.head.appendChild(el);
        return el;
      },

      createStyleLinkElement() {
        let el = document.createElement('link');
        el.setAttribute('rel', 'stylesheet');
        return el;
      },

      loadMultipleFonts(fonts) {
        let queue = [];

        fonts.forEach((fontName) => {
          let font = new window['FontFaceObserver'](fontName);
          queue.push(font.load());
        });

        return Promise.all(queue);
      },
    },
  },
};
