import Vue from 'vue';

import RoutingService from './RoutingService';
import PagesService from './PagesService';

export default {
  name: 'vue',

  dependencies: {
    RoutingService,
    PagesService,
  },

  hooks: {
    app: {
      init(registry) {
        // Wait for vue to be loaded.
        if (
          registry.AssetsService === 'complete' &&
          registry.RoutingService === 'complete' &&
          registry.PagesService === 'complete'
        ) {
          this.addLib('vue', Vue);

          let app = this;
          this.vue.globalMixin = {
            props: {
              app: {
                default: () => {
                  return app;
                },
              },
            },
          };

          this.mix(this.vue.globalMixin, 'vue', false, false);

          this.lib.vue.mixin(this.vue.globalMixin);

          return 'complete';
        }
        return 'wait';
      },

      loadAppData(data, registry) {
        // Need vue templates to be loaded into page content before to load components assets.
        if (registry.AssetsService !== 'complete') {
          return 'wait';
        }

        this.vue.addTemplatesHtml(data.vueTemplates);

        return 'complete';
      },
    },
  },

  methods: {
    app: {
      renderedTemplates: {},
      componentRegistered: {},
      vueGlobalMixin: null,
      vueRegistry: {},

      // TODO Used in Laravel only, must be merged / isolated.
      buildSingleComponent(el, module, tagName = null, options = {}) {
        el = typeof el === 'string' ? document.querySelector(el) : el;
        tagName = tagName || el.tagName.toLowerCase();

        this.lib.vue.component(tagName, module.default);

        return new this.lib.vue({
          ...{
            el: el,
            app: this,
          },
          ...options,
        });
      },

      createComName(path) {
        return path.split('/').join('-').toLowerCase();
      },

      inherit(vueComponent) {
        let componentsFinal = vueComponent.components || {};
        let extend = {} as any;

        if (vueComponent.extends) {
          extend = this.vue.inherit(vueComponent.extends) as any;
        }

        let componentsStrings = {
          ...extend.components,
          ...componentsFinal,
        };

        // Convert initial strings to initialized component.
        Object.entries(componentsStrings).forEach((data) => {
          // Prevent to initialize already converted object.
          if (typeof data[1] === 'string') {
            vueComponent.components[data[0]] = this.vue.initComponent(data[1]);
          }
        });

        return vueComponent;
      },

      initComponent(className) {
        let comName = this.vue.createComName(className);

        if (!this.vue.componentRegistered[className]) {
          let vueComponent = window['appRegistry'].vue.classes[className] as any;

          if (!vueComponent) {
            this.errors.system('page_message.error.vue_missing_component', {
              ':class': className,
              ':component': comName,
            });
          } else {
            let id = 'vue-template-' + comName;
            vueComponent.template = document.getElementById(id);

            if (!vueComponent.template) {
              throw new Error(
                `Unable to load vue component as template item #${id} has not been found.`
              );
            }

            vueComponent.methods = vueComponent.methods || {};
            vueComponent.methods.getClassName = () => className;
            vueComponent.methods.getComName = () => comName;
            vueComponent.mixins = (vueComponent.mixins || []).concat([
              this.vue.globalMixin,
            ]);

            this.vue.inherit(vueComponent);

            this.lib.vue.component(comName, vueComponent);
            this.vue.componentRegistered[className] = vueComponent;
          }
        }

        return this.vue.componentRegistered[className];
      },

      addTemplatesHtml(renderedTemplates) {
        let elContainer = document.getElementById('vue-templates');

        for (let name in renderedTemplates) {
          if (!this.vue.renderedTemplates[name]) {
            elContainer.insertAdjacentHTML(
              'beforeend',
              renderedTemplates[name]
            );
            this.vue.renderedTemplates[name] = true;
          }
        }
      },
    },

    vue: {
      mounted() {
        // We have an issue with root vue elements that fires twice.
        if (this === this.$root) {
          return;
        }

        let callbacksBag = [];
        // Ask mixins for vue mounted action,
        // especially for components initialization.
        this.app.mixin.invokeUntilComplete('mounted', 'vue', [callbacksBag], () =>
          callbacksBag.forEach((callback) => callback.apply(this))
        );
      },
      methods: {
        emitBubblingCustomEvent(name, options) {
          this.$el.dispatchEvent(
            new CustomEvent(name, {
              ...options,
              ...{
                details: {
                  component: this,
                },
                bubbles: true,
              },
            })
          );
        },

        subItems(itemsList) {
          return this.app.api.subItems(itemsList);
        },
      },
    },
  },
};
