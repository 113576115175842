export default {
  name: 'animation',

  methods: {
    app: {
      easing: {
        linear(t, b, c, d) {
          return c * (t / d);
        },
        easeOutQuint(t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        },
      },

      degToRad: Math.PI / 180,
      timeoutsRegistry: {},

      timeOutOnce(name, callback, delay = 300) {
        if (this.animation.timeoutsRegistry[name]) {
          clearTimeout(this.animation.timeoutsRegistry[name]);
        }
        this.animation.timeoutsRegistry[name] = setTimeout(callback, delay);
      },

      animateElHeightAuto(el, options:any = {}) {
        options.suffix = options.suffix || 'px';
        options.clearAtEnd =
          options.clearAtEnd !== undefined ? options.clearAtEnd : true;
        let style = el.style;

        let from = options.from || style.height;
        style.height = 'auto';
        let to = options.to || el.getBoundingClientRect().height;
        style.height = from;
        // Element should not have scrollbar during animation,
        // but it may use it once finished.
        style.overflowY = 'hidden';

        let complete = options.complete;
        options.complete = () => {
          style.overflowY = null;
          complete && complete();
        };

        return this.animation.animateObject(
          el.style,
          'height',
          to,
          options.duration,
          options
        );
      },

      animate(
        from,
        to,
        duration = 500,
        {
          complete,
          step,
          easing = 'linear',
          fps = 24,
          animationStorage = {},
        }:any = {}
      ) {
        let count = 0;
        let intervals = (duration / 1000) * fps;
        let durationStep = Math.round(duration / intervals);

        // Stop if an animation exists in the given storage.
        if (animationStorage.timeOut) {
          clearTimeout(animationStorage.timeOut);
        }

        let callback = () => {
          let current =
            from +
            this.animation.easing[easing](count, 0, to - from, intervals);
          count++;

          step && step(current, from, to, count, intervals);

          if (count <= intervals) {
            animationStorage.timeOut = setTimeout(callback, durationStep);
          } else {
            animationStorage.timeOut && clearTimeout(animationStorage.timeOut);
            complete && complete();
          }
        };

        callback();
      },

      animateObject(
        object,
        property,
        to,
        duration,
        { clearAtEnd = false } = {}
      ) {
        let options = arguments[4];

        // Attache a storage to keep relation between animation timeout an object property.
        // This allow to stop running animation if a new one is launched on the same property.
        object.animationsStorage = object.animationsStorage || {};
        object.animationsStorage[property] =
          object.animationsStorage[property] || {};
        options.animationStorage = object.animationsStorage[property];
        options.suffix = options.suffix || '';

        let origStep = options.step;
        options.step = (current, from, to, count, intervals) => {
          object[property] = current + options.suffix;
          origStep && origStep(current, from, to, count, intervals);
        };

        if (clearAtEnd) {
          let origComplete = options.complete;
          options.complete = () => {
            object[property] = null;
            delete object.animationsStorage[property];
            origComplete && origComplete();
          };
        }

        return this.animation.animate(
          parseFloat(object[property]) || 0,
          to,
          duration,
          options
        );
      },
    },
  },
};
