export default {
  name: 'location',

  hooks: {
    app: {
      init() {
        this.location.paramReload();
      },
    },
  },

  methods: {
    app: {
      paramsHash: null,

      paramReload() {
        this.location.paramsHash = new URLSearchParams(
          document.location.hash.substr(1)
        );
      },

      hashParamGet(name, defaultValue) {
        this.location.paramReload();

        let value = this.location.paramsHash.get(name);
        // Support "false" value in query string, but not "null".
        return value !== null ? value : defaultValue;
      },

      hashParamSet(name, value, ignoreHistory = false) {
        let location = document.location;
        this.location.paramsHash.set(name, value);

        this.location.updateLocation(
          location.pathname +
            location.search +
            '#' +
            this.location.paramsHash.toString(),
          ignoreHistory
        );
      },

      hashParamRemove(name, ignoreHistory = false) {
        let location = document.location;
        this.location.paramsHash.delete(name);

        this.location.updateLocation(
          location.pathname +
            location.search +
            '#' +
            this.location.paramsHash.toString(),
          ignoreHistory
        );
      },

      updateLocation(href, ignoreHistory = false) {
        // Cleanup href if no more hash.
        if (href[href.length - 1] === '#') {
          href = href.substr(0, href.length - 1);
        }

        // Choose between push or replace.
        history[ignoreHistory ? 'replaceState' : 'pushState'](
          { manualState: true },
          document.title,
          href
        );
      },

      getUrlFromCssRule(url) {
        // Get url from css "url('xxx')" style value.
        return url.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
      },

      parseUrl(url) {
        // Not absolute.
        if (url.substr(0, 4) !== 'http') {
          // Append root slash.
          if (url[0] !== '/') {
            url = '/' + url;
          }
          url = document.location.origin + url;
        }
        return new URL(url);
      },

      buildUrl(urlObject) {
        return (
          urlObject.origin +
          urlObject.pathname +
          urlObject.search +
          urlObject.hash
        );
      },

      urlUpdateQueryString(url, key, value) {
        let query = {};
        query[key] = value;
        return this.location.urlAddQueryObject(url, query);
      },

      urlAddQueryObject(url, query) {
        let urlObject = this.location.parseUrl(url);

        urlObject.search = this.location.objectToQueryString(
          query,
          new URLSearchParams(urlObject.search)
        );

        return this.location.buildUrl(urlObject);
      },

      objectToQueryString(object, searchParams = new URLSearchParams()) {
        Object.entries(object).forEach((data: any) => {
          searchParams.set(data[0], data[1]);
        });

        return searchParams.toString();
      },

      /**
       * Return the same url with a rand=xxx var to enforce caches.
       * @param url
       * @param key
       * @returns {string}
       */
      urlRefresh(url, key = 'rand') {
        return this.location.urlUpdateQueryString(
          url,
          key,
          new Date().getTime() + Math.random()
        );
      },
    },
  },
};
