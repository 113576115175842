export default {
  name: 'errors',

  methods: {
    app: {
      system(message, args = {}, level = 'critical', data) {
        // We should improve this to send extra data to server reports.
        this.prompt.toastError(message, args, data);
      },
    },
  },
};
