import axios from 'axios';

export default {
  name: 'ajax',

  hooks: {
    app: {
      init() {
        this.addLib('axios', axios);

        if (window['appData'].user && window['appData'].user.id) {
          // Allow api requests.
          this.lib.axios.defaults.headers.common['Authorization'] =
            'Bearer ' + window['appData'].user.api_token;
        }
      },
    },
  },

  methods: {
    app: {
      getV2(path, data) {
        return this.lib.axios.get(path, this.ajax.wrapData(data));
      },

      postV2(path, data) {
        return this.lib.axios.post(path, this.ajax.wrapData(data));
      },

      /**
       * Inspired from https://stackoverflow.com/a/18078705
       * Used for send / get / post methods.
       */
      createRequest() {
        if (typeof XMLHttpRequest !== 'undefined') {
          return new XMLHttpRequest();
        }
        let versions = [
          'MSXML2.XmlHttp.6.0',
          'MSXML2.XmlHttp.5.0',
          'MSXML2.XmlHttp.4.0',
          'MSXML2.XmlHttp.3.0',
          'MSXML2.XmlHttp.2.0',
          'Microsoft.XmlHttp',
        ];

        let xhr;
        for (let i = 0; i < versions.length; i++) {
          try {
            xhr = new ActiveXObject(versions[i]);
            break;
          } catch (e) {}
        }
        return xhr;
      },

      send(options) {
        if (options.async === undefined) {
          options.async = true;
        }
        let x = this.ajax.createRequest();
        x.open(options.method, options.url, options.async);
        x.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        x.onreadystatechange = function () {
          if (x.readyState === 4) {
            options.callback && options.callback(x.responseText);
          }
        };
        if (
          !options.contentType &&
          options.method === 'POST' &&
          options.urlEncoded
        ) {
          options.contentType = 'application/x-www-form-urlencoded';
        }
        if (options.contentType) {
          x.setRequestHeader('Content-type', options.contentType);
        }
        x.send(options.data);
      },

      sendEncodeData(data) {
        let query = [];
        for (let key in data) {
          query.push(
            encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
          );
        }
        return query;
      },

      get(url, query, callback, options) {
        if (query && Object.values(query).length) {
          url = this.location.urlAddQueryObject(url, query);
        }

        this.ajax.send({
          ...{
            url: url,
            method: 'GET',
            callback: callback,
          },
          ...options,
        });
      },

      getJSON(url, query, callback, options) {
        return this.ajax.get(
          url,
          query,
          (r) => {
            return callback && callback(JSON.parse(r));
          },
          options
        );
      },

      post(url, data, callback, options) {
        this.ajax.send({
          ...{
            url: url,
            method: 'POST',
            data: this.ajax.sendEncodeData(data).join('&'),
            callback: callback,
            urlEncoded: true,
          },
          ...options,
        });
      },

      postFile(url, file, name, success, error) {
        let options = {
          success: success,
          error: error,
        };

        // Create a new FormData object.
        let formData = new FormData();
        formData.append(name, file, file.name);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        // Set header, allows adaptive event to handle it as xhr.
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        // Set up a handler for when the request finishes.
        xhr.onload = function () {
          if (xhr.status === 200) {
            let output;
            try {
              output = JSON.parse(this.responseText);
            } catch (e) {
              output = this.responseText;
            }
            // File(s) uploaded.
            options.success && options.success(output);
          } else {
            options.error && options.error(this);
          }
        };

        // Send the Data.
        xhr.send(formData);
      },

      wrapData(data) {
        // Handle false values.
        data = data || {};

        // No auth data defined,
        // try to use a default credentials couple :
        // - username : domain (no subdomain, no extension, ie: google)
        // - password : environement name (ie: dev)
        if (window['appData'].page.vars.basicAuth && !data.auth) {
          let split = document.location.host.split('.');
          let username = split[split.length - 2];

          Object.assign(data, {
            auth: {
              username: username,
              password: window['appData'].env,
            },
          });
        }

        return data;
      },
    },
  },
};
