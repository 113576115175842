export default {
  name: 'object',

  methods: {
    app: {
      toType(a) {
        // Get fine type (object, array, function, null, error, date ...)
        return {}.toString.call(a).match(/([a-z]+)(:?\])/i)[1];
      },

      isDeepObject(obj) {
        return 'Object' === this.object.toType(obj);
      },

      deepAssign() {
        return this.object
          .deepAssignWithOptions({
            nonEnum: true,
            symbols: true,
            descriptors: true,
            proto: true,
          })
          .apply(this, arguments);
      },

      /**
       * Deep assign implementation. Quite arbitrary choice as there is a lot of different version.
       * This one seems pretty configurable.
       *
       * @see https://stackoverflow.com/a/48579540
       * @param options
       * @returns {function(*=, ...[*]): *}
       */
      deepAssignWithOptions(options) {
        return (target, ...sources) => {
          sources.forEach((source) => {
            if (
              !this.object.isDeepObject(source) ||
              !this.object.isDeepObject(target)
            )
              return;

            // Copy source's own properties into target's own properties
            let copyProperty = (property) => {
              const descriptor = Object.getOwnPropertyDescriptor(
                source,
                property
              );
              //default: omit non-enumerable properties
              if (descriptor.enumerable || options.nonEnum) {
                // Copy in-depth first
                if (
                  this.object.isDeepObject(source[property]) &&
                  this.object.isDeepObject(target[property])
                )
                  descriptor.value = this.object.deepAssignWithOptions(options)(
                    target[property],
                    source[property]
                  );
                //default: omit descriptors
                if (options.descriptors)
                  Object.defineProperty(target, property, descriptor);
                // shallow copy descriptor
                else target[property] = descriptor.value; // shallow copy value only
              }
            };

            // Copy string-keyed properties
            Object.getOwnPropertyNames(source).forEach(copyProperty);

            //default: omit symbol-keyed properties
            if (options.symbols)
              Object.getOwnPropertySymbols(source).forEach(copyProperty);

            //default: omit prototype's own properties
            if (options.proto)
              // Copy souce prototype's own properties into target prototype's own properties
              this.object.deepAssignWithOptions(
                Object.assign({}, options, { proto: false })
              )(
                // Prevent deeper copy of the prototype chain
                Object.getPrototypeOf(target),
                Object.getPrototypeOf(source)
              );
          });
          return target;
        };
      },
    },
  },
};
