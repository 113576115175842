import Component from '../class/Component';
import { findPreviousNode } from "../helpers/DomHelper";

export default {
  name: 'components',

  hooks: {
    app: {
      init() {
        if (this.debug) {
          let origMethod;

          origMethod = this.components.add;
          this.components.add = () => {
            this.components.history.push({
              type: 'add',
              time: Math.round(new Date().getTime() / 1000),
            });
            origMethod.apply(this, arguments);
          };

          origMethod = this.components.remove;
          this.components.remove = () => {
            this.components.history.push({
              type: 'remove',
              time: Math.round(new Date().getTime() / 1000),
            });
            origMethod.apply(this, arguments);
          };
        }
      },

      // Parse components when receiving a response.
      loadAppData(data, registry) {
        if (
          registry.VueService !== 'complete' ||
          registry.AssetsService !== 'complete'
        ) {
          return 'wait';
        }

        data.pageTemplateContent &&
          this.components.appendPageTemplateContent(data.pageTemplateContent);

        // Init classes based on loaded assets,
        // components classes may define
        // non registered components instances.
        Object.entries(data.assets.js).forEach((entry) => {
          let exp = entry[1]['group'].split('.');
          let prefix = exp.shift();

          if (prefix === 'components') {
            let name = exp.join('.');
            let definition = this.getClassDefinition(
              'components',
              Component,
              name
            );

            if (definition.initializedClass) {
              return;
            }

            definition.initializedClass = true;

            definition.initClass(this);
          }
        });

        this.components.addToCatalog(data.components);

        this.components.initAll(document.getElementById('main'), 'main', null);

        return 'complete';
      },
    },
    vue: {
      mounted: (callbacksBag) => {
        callbacksBag.push(function () {
          // For internal vue components only.
          if (this.getComName) {
            let getComName = this.getComName();

            if (getComName) {
              this.app.components.initAll(this.$el, 'vue', getComName);
            }
          }
        });

        return 'complete';
      },
    },
  },

  methods: {
    app: {
      instances: {},
      errors: {},
      catalog: [],
      history: [],

      addToCatalog(stack) {
        stack &&
          (this.components.catalog = this.components.catalog.concat(stack));
      },

      add(component) {
        this.components.instances[component.id] = component;
      },

      remove(id) {
        delete this.components.instances[id];
      },

      appendPageTemplateContent(html) {
        // Append html for global components.
        document
          .getElementById('components-main')
          .insertAdjacentHTML('beforeend', html);
      },

      initAll(elContext, initContextType, initContextName) {
        let output = [];
        let remaining = [];

        this.components.catalog.forEach((data) => {
          let keep;

          if (
            data.initContext.type === initContextType &&
            data.initContext.name === initContextName
          ) {
            output.push(this.components.init(elContext, data));
            // Keep components that are part of vue template.
            keep = initContextType === 'vue';
          } else {
            keep = true;
          }

          if (keep) {
            remaining.push(data);
          }
        });

        this.components.catalog = remaining;

        return output;
      },

      init(elContext, data) {
        let name = data.name;

        if (!window['appRegistry'].components.classes[name]) {
          // Prevent multiple alerts for the same component.
          if (!this.components.errors[name]) {
            this.components.errors[name] = true;
            this.errors.system('page_message.error.com_missing', {
              ':type': name,
            });
          }
        } else {
          let definition = window['app'].getClassDefinition(
            'components',
            Component,
            name
          );

          data.app = this;
          data.context = elContext;

          if (definition.getStaticProperties().hasTag) {
            let elPlaceholder = data.context.querySelector('.' + data.id);

            switch (data.type) {
              case 'parent':
                if (elPlaceholder) {
                  data.el = elPlaceholder.parentNode;
                }
                break;
              case 'previous':
              case 'server':
                if (elPlaceholder) {
                  data.el = findPreviousNode(elPlaceholder);
                }
                break;
              case 'class':
                data.el = elPlaceholder;
                elPlaceholder = null;
                break;
            }

            if (!data.el) {
              this.components.loadError(data.id, data.name, data.type);
              return;
            }

            if (elPlaceholder) {
              // Remove placeholder tag as it may interact with CSS or JS selectors.
              elPlaceholder.parentNode.removeChild(elPlaceholder);
            }
          }

          return new definition(data);
        }
      },

      loadError(id, name, type) {
        this.errors.system(
          'page_message.error.com_missing_placeholder_' + type,
          {
            ':id': id,
            ':name': name,
          }
        );
      },
    },
  },
};
