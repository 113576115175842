export default {
  name: 'modal',

  hooks: {
    app: {
      init(registry) {
        // We will play with location hash.
        if (
          registry.LocationService === 'complete' &&
          registry.PagesService === 'complete'
        ) {
          // This is a redirection.
          if (
            new URLSearchParams(window.location.search).has(
              'adaptiveRedirection'
            )
          ) {
            // Do not open modal and clear query string.
            this.modal.close(true);
            return 'complete';
          }
          // Wait for all mixins loaded (enqueue after components callback).
          this.ready(() => {
            // React to history changes.
            window.addEventListener(
              'hashchange',
              this.modal.getFromHash.bind(this)
            );
            // Check if modal expected.
            this.modal.getFromHash();
          });
          return 'complete';
        }
        // Need to rerun hook later.
        return 'wait';
      },
    },
  },

  methods: {
    app: {
      modalMain: null,

      getFromHash() {
        let modalPath = this.location.hashParamGet('modalMain');

        if (modalPath) {
          this.modal.get(modalPath, { ignoreHistory: true });
        } else if (this.modal.modalMain) {
          this.modal.modalMain.close(true, true);
        }
      },

      get(
        path,
        options:any = {},
        destModalComponent = this.modal.modalMain,
        callback
      ) {
        let hasModal = destModalComponent.opened;

        options.query = options.query || {};
        options.query.layout = options.query.layout || 'modal';
        options.modal = options.modal || {};

        // Temp fix, remove unwanted param in url.
        let closeCallback = options.modal.closeCallback;
        delete options.modal.closeCallback;

        // Save modal as opened in url (may be used in all pages).
        Object.assign(options.query, options.modal);

        this.page.get(path, destModalComponent.el, options, (response) => {
          // Page may be missing in case of error on unexpected response.
          if (response.page) {
            let heightFrom =
              hasModal &&
              this.modal.modalMain.elContent.getBoundingClientRect().height;

            options.modal.page = response.page;
            options.modal.closeCallback = closeCallback;

            // Add data to main modal.
            this.modal.modalMain.fill(options.modal);

            if (hasModal) {
              this.animation.animateElHeightAuto(
                this.modal.modalMain.elContent,
                {
                  from: heightFrom + 'px',
                  easing: 'easeOutQuint',
                }
              );
            }

            destModalComponent.open(response);

            this.location.hashParamSet(
              'modalMain',
              path,
              options.ignoreHistory
            );
          }
          callback && callback(response);
        });
      },

      close(ignoreHistory) {
        this.location.hashParamRemove('modalMain', ignoreHistory);
      },

      routeGet(route, options = {}, modalOptions = {}, callback) {
        return this.modal.get(
          this.routing.path(route, options),
          modalOptions,
          undefined,
          callback
        );
      },
    },
  },
};
