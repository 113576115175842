export default {
  name: 'locale',

  hooks: {
    app: {
      loadAppData(data) {
        // Add new global translations.
        // May not exists in ajax response.
        if (data.trans) {
          Object.assign(window['appData'].trans.catalog, data.trans.catalog);
        }

        return 'complete';
      },
    },
  },

  methods: {
    app: {
      transDomains: {},

      trans(
        string = '',
        args = {},
        domains = this.locale.transDomains,
        catalog = window['appData'].trans.catalog
      ) {
        catalog = catalog || window['appData'].trans.catalog;
        let stringWithDomain;
        let sep = window['appData'].trans.separator;

        // A domain is specified.
        if (string.indexOf(sep) !== -1) {
          if (string[0] === '@') {
            let exp = string.split(sep);
            let domainPart = exp[0].substr(1);
            if (domains[domainPart]) {
              stringWithDomain = domains[domainPart] + sep + exp[1];
            }
          } else {
            stringWithDomain = string;
          }
        }
        // Use default domain
        else {
          stringWithDomain = 'messages' + sep + string;
        }

        return this.string.format(catalog[stringWithDomain] || string, args);
      },
    },

    page: {
      trans(string = '', args = {}) {
        return this.app.locale.trans(
          string,
          args,
          this.translations.domains,
          Object.assign({}, window['appData'].trans.catalog, this.translations.catalog)
        );
      },
    },

    vue: {
      methods: {
        trans: function (string, args = {}, domains = window['app'].locale.transDomains) {
          // Resolve "@vue::" to "vue.name::"
          domains = Object.assign({}, domains, {
            vue: this.getClassName().replace(/\//g, '.'),
          });

          return window['app'].locale.trans(
            string,
            args,
            domains,
            // Vue catalog can reside into page.
            this.$root.component.page
              ? this.$root.component.page.data.trans.catalog
              : // or on global catalog if no page associated.
                window['appData'].trans.catalog
          );
        },
      },
    },
  },
};
