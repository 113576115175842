export default {
  name: 'api',

  methods: {
    app: {
      get(path, data) {
        return this.ajax.getV2(this.api.urlApi(path), data);
      },
      post(path, data) {
        return this.ajax.postV2(this.api.urlApi(path), data);
      },

      urlApi(path, filters) {
        let url = '/api/' + path;
        if (filters) {
          if (url.indexOf('?') === -1) {
            url += '?';
          }
          url += filters;
        }
        return url;
      },

      getEntityApi(entity, actionName, callback, data, options) {
        this.api.getApi(
          `item/${entity.entityType}/${entity.id}/${actionName}`,
          callback,
          data,
          options
        );
      },

      getApi(url, callback, data, options) {
        this.ajax.get(
          this.api.urlApi(url),
          data,
          (response) => {
            try {
              response = JSON.parse(response);
            } catch (e) {
              response = {
                API_STATUS: 'error',
                API_MESSAGE: window['app'].locale.trans(
                  'page_message.error.api_response',
                  {
                    ':body': response,
                  }
                ),
              };
            }

            if (response.API_STATUS === 'error') {
              this.errors.system(response.API_MESSAGE);
            }

            let data = response;

            // Try to parse content.
            if (response['hydra:member']) {
              data = response['hydra:member'];
            }

            callback(data, response);
          },
          options
        );
      },

      subItems(itemsList) {
        let output = [];

        for (let i in itemsList) {
          if (i !== 'hydra:view') {
            output.push(itemsList[i]);
          }
        }

        return output;
      }
    },
  },
};
