export default {
  name: 'lazy',

  hooks: {
    app: {
      loadAppData(data, registry) {
        if (registry.PagesService === 'complete') {
          // TODO Make it work.
          // this.lazy.activateAll(data.page.instance.el);
          // this.lazy.observeAll(data.page.instance.el);

          return 'complete';
        }
        return 'wait';
      },
    },
  },

  methods: {
    app: {
      observeAll(elParent) {
        ['src', 'background-image'].forEach((action) => {
          let method = this.string.toCamel('activate-' + action);

          // Images
          elParent.querySelectorAll(`[data-lazy-${action}]`).forEach((el) => {
            this.lazy.observeEl(el, (entry, observer) => {
              if (entry.isIntersecting) {
                this.lazy[method](el);

                el.classList.remove('lazy-waiting');

                observer.unobserve(el);
              }
            });
          });
        });
      },

      observeEl(el, onEvent) {
        let observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => onEvent(entry, observer));
          },
          { threshold: [0] }
        );

        el.classList.add('lazy-waiting');

        observer.observe(el);
      },

      activateAll(el) {
        this.lazy.activateBackgroundImages(el);
      },

      activateBackgroundImages(elParent) {
        elParent
          .querySelectorAll('[data-lazy-background-image]')
          .forEach((el) => this.lazy.activateBackgroundImage(el));
      },

      activateBackgroundImage(el) {
        el.style.backgroundImage = `url(${el.getAttribute(
          'data-lazy-background-image'
        )})`;
        el.removeAttribute('data-lazy-background-image');
      },

      activateSrc(el) {
        el.setAttribute('src', el.getAttribute('data-lazy-src'));
        el.removeAttribute('data-lazy-src');
      },
    },
  },
};
