import Routing from '../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

export default {
  name: 'routing',

  hooks: {
    app: {
      init() {
        Routing.setRoutingData(
          require('../../../../../../public/js/fos_js_routes.json')
        );
      },
    },
  },

  methods: {
    app: {
      path(route, params) {
        return Routing.generate(route, params);
      },
    },
    vue: {
      methods: {
        path: function () {
          return window['app'].routing.path.apply(window['app'], arguments);
        },
      },
    },
  },
};
