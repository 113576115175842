export default {
  name: 'page',

  hooks: {
    app: {
      init() {
        this.page.elMainOverlay = document.getElementById('main-overlay');
        this.page.elMainSpinner = document.getElementById('main-spinner');
        return 'complete';
      },

      loadAppData(data, registry) {
        if (
          registry.AssetsService === 'complete' &&
          registry.LocaleService === 'complete' &&
          registry.VueService === 'complete'
        ) {
          // If a page exists in response.
          if (data.page) {
            if (data.page.instance) {
              data.page.instance.loadData({
                vars: data.vars,
                trans: data.trans,
              });
            } else {
              this.page.create(data.page);
            }
          }
          return 'complete';
        }
        return 'wait';
      },
    },
  },

  methods: {
    app: {
      registry: {},

      create(data) {
        try {
          let classDefinition = this.getClassDefinition(
            'pages',
            this.getClassPage(),
            data.name
          );

          return new classDefinition(this, data);
        } catch (e) {
          console.error(e);
          this.errors.system('Error during page creation.');
        }
      },

      getPageInstance(data) {
        return (data.page && data.page.instance) || window['appData'].page.instance;
      },

      get(path, elDest, options:any = {}, callback) {
        options.query = options.query || {};
        options.query.layout = options.query.layout || 'page';

        // Add dest element to data after call success.
        options.parseData = (data) => {
          // Page may be missing, for example if an error message is triggered instead.
          if (data.page) {
            data.page.el = elDest;
          }
        };

        this.adaptive.get(path, options, (r) => {
          callback && callback(r);
        });
      },
    },
  },
};
