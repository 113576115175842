import moment from 'moment';

export default {
  name: 'date',

  hooks: {
    app: {
      init(registry) {
        if (registry.VueService === 'complete') {
          this.addLib('moment', moment);

          moment.locale(window['appData'].trans.locale);

          // Setup calendar (europe).
          // @see https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
          moment.updateLocale(window['appData'].trans.locale, {
            week: {
              dow: 1, // First day of week is Monday
              doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
            },
          });

          this.lib.vue.mixin({
            filters: {
              date: this.date.format.bind(this),
            },
          });
          return 'complete';
        }
        return 'wait';
      },
    },
  },

  methods: {
    app: {
      dateFormat: {
        approximate: 'approximate',
        numeric_medium: 'numeric_medium',
        numeric_short: 'numeric_short',
      },

      stamp(date) {
        return Math.round(date.getTime() / 1000);
      },

      trans(message, params = {}) {
        return this.locale.trans(
          'date.format_' + this.date.dateFormat.approximate + '.' + message,
          params
        );
      },

      format(stamp, format = this.date.dateFormat.approximate) {
        if (!stamp) {
          return '';
        }

        let dateMoment = moment.unix(stamp);
        let dateNow = new Date();

        switch (format) {
          case this.date.dateFormat.approximate:
            let stampNow = this.date.stamp(dateNow);
            let diffSeconds = stampNow - stamp;

            // Ignore future dates.
            if (diffSeconds > 0) {
              if (diffSeconds < 5) {
                return this.date.trans('just_now');
              }

              let diffMinute =
                this.date.stamp(
                  dateNow.setHours(
                    dateNow.getHours(),
                    dateNow.getMinutes(),
                    0
                  ) && dateNow
                ) - stamp;

              // Time was during current minute.
              if (diffMinute <= 0) {
                // Display only seconds.
                return this.date.trans('seconds', {
                  ':seconds': diffSeconds,
                });
              }
              let minutes = (stampNow - stamp) / 60;

              // Time was under one minute.
              if (minutes <= 1) {
                return this.date.trans('minute');
              }

              let diffHour =
                this.date.stamp(
                  dateNow.setHours(dateNow.getHours(), 0, 0) && dateNow
                ) - stamp;

              // Time was during this hour.
              if (diffHour <= 0) {
                // Display only minutes.
                return this.date.trans('minutes', {
                  ':minutes': Math.ceil(minutes),
                });
              }

              let diffDay =
                this.date.stamp(dateNow.setHours(0, 0, 0) && dateNow) - stamp;

              diffDay =
                diffDay === 0 ? diffDay : Math.ceil(diffDay / 60 / 60 / 24);

              // Time was during this day.
              if (diffDay <= 0) {
                // Display only hour and minutes.
                return this.date.trans('today_at', {
                  ':hour': dateMoment.format('H'),
                  ':minutes': dateMoment.format('mm'),
                });
              }

              // Time was yesterday.
              if (diffDay <= 1) {
                // Display only hour and minutes.
                return this.date.trans('yesterday_at', {
                  ':hour': dateMoment.format('H'),
                  ':minutes': dateMoment.format('mm'),
                });
              }

              if (dateNow.getFullYear().toString() === dateMoment.format('Y')) {
                return this.date.trans('this_year', {
                  ':date': dateMoment.format('D MMMM YYYY'),
                  ':time':
                    dateMoment.format('HH') +
                    'h' +
                    dateMoment.format('mm') +
                    'm',
                });
              }
            }

            // With year specified.
            return this.date.trans('default', {
              ':date': dateMoment.format('D MMMM YYYY'),
              ':time':
                dateMoment.format('HH') + 'h' + dateMoment.format('mm') + 'm',
            });
          case this.date.dateFormat.numeric_short:
          case this.date.dateFormat.numeric_medium:
            let diffDay =
              this.date.stamp(dateNow.setHours(0, 0, 0) && dateNow) - stamp;

            diffDay =
              diffDay === 0 ? diffDay : Math.ceil(diffDay / 60 / 60 / 24);

            // Time was during this day.
            if (diffDay === 0) {
              // Display only hour and minutes.
              return dateMoment.format('HH:mm');
            }

            return dateMoment.format(
              'DD/MM' +
                (format === this.date.dateFormat.numeric_medium ? '/YYYY' : '')
            );
        }

        // Fallback on the custom format.
        return dateMoment.format(format);
      },

      getMonth(date = new Date()) {
        return this.date.keepLeadingZero(date.getMonth() + 1);
      },

      getYear(date = new Date()) {
        return date.getFullYear();
      },

      keepLeadingZero(number) {
        number = parseInt(number);
        return number < 10 ? `0${number}` : `${number}`;
      },
    },

    vue: {
      methods: {
        date: function () {
          return this.app.date.format.apply(this.app, arguments);
        },
      },
    },
  },
};
