export default class Component {
  constructor(data) {
    // Merge data.
    Object.assign(this, this.getProperties());
    Object.assign(this, data);

    // TODO TS conversion
    this['data'] = data;
    // TODO TS conversion
    this['app'].components.add(this);

    this.init(data.options);
  }

  static initClass() {
    // To override, global initialisation without instantiation.
  }

  static getStaticProperties() {
    return { hasTag: true };
  }

  remove() {
    // TODO TS conversion
    this['app'].components.remove(this['id']);
  }

  getProperties() {
    return {};
  }

  init(options) {
    // To override...
  }
}
