export default {
  name: 'entity',

  methods: {
    app: {
      get(type, id, path, data) {
        return this.api.get(`entity/${type}/${id}/${path}`, data);
      },
      post(type, id, path, data) {
        return this.api.post(`entity/${type}/${id}/${path}`, data);
      },
      updateField(type, id, fieldName, value) {
        let fields = {};
        fields[fieldName] = value;
        return this.entity.updateFields(type, id, fields);
      },
      updateFields(type, id, fields) {
        return this.entity.post(type, id, 'update-model-fields', {
          fields: fields,
        });
      },
      postCollection(entityType, url, data, callback, options:any = {}) {
        options.contentType = 'application/json';

        this.ajax.send({
          ...{
            url: this.api.urlApi(
              `collection/${entityType}/${url}`,
              options.apiFilters
            ),
            method: 'POST',
            data: JSON.stringify({members: data}),
            callback: callback,
          },
          ...options,
        });
      },

      editField(name, value, entity, complete, update = true) {
        this.entity.send(
          `edit-field/${name}/${value}`,
          complete,
          entity,
          update
        );
      },

      /**
       * Should be rewritten.
       */
      editFieldPost(name, data, entity) {
        this.api.post(
          `item/${this.string.toKebab(entity.entityType)}/${
            entity.id
          }/edit-field/${name}`,
          {
            fieldData: data,
          }
        );
      },

      getV1(entityType, id, callback) {
        this.api.getApi(`item/${entityType}/${id}`, callback);
      },

      hasMessage(entity, messageType) {
        let messages = this.api.subItems(entity.entityMessages);

        if (!messageType) {
          return !!messages.length;
        } else {
          return messages.some((message) => {
            return message.type === messageType;
          });
        }
      },

      reload(complete, entity, update = true) {
        this.entity.send(
          ``,
          (response) => complete && complete(response),
          entity,
          update
        );
      },

      remove(complete, entity) {
        this.entity.send(`remove`, complete, entity);
      },

      send(path, complete, entity, update = true) {
        path = `item/${this.string.toKebab(entity.entityType)}/${
          entity.id
        }/${path}`;
        path += path.indexOf('?') === -1 ? '?' : '';
        path += `&display_format=${entity.displayFormat}`;

        this.api.getApi(path, (newEntity) => {
          if (update) {
            Object.assign(entity, newEntity);
          }

          complete && complete(newEntity, entity);
        });
      },
    },

    vue: {
      methods: {
        loadVueComponentsFromEntitiesProperty(propertyName, complete) {
          // Prevent watchers infinite loops.
          if (this[propertyName].length === 0) {
            return false;
          }

          let entities = this[propertyName];

          let loading = this.loadVueComponentsFromEntities(
            entities,
            (loading) => {
              // Only update list if a loading has been executed.
              if (loading) {
                this[propertyName] = entities;
              }

              complete && complete();
            }
          );

          if (loading) {
            // Prevent to render items of non loaded components.
            this[propertyName] = [];
          }

          return loading;
        },

        loadVueComponentsFromEntities(entities, complete) {
          let query = {};
          let buildMissing = () => {
            let hasMissing = false;
            entities.forEach((entity) => {
              if (
                !this.app.vue.componentRegistered[
                  `vue/entity/${entity.entityPath}`
                  ]
              ) {
                query[entity.entityPath] = `name[]=${entity.entityPath}`;
                hasMissing = true;
              }
            });

            return hasMissing;
          }

          let hasMissing = buildMissing();

          if (hasMissing) {
            let queryString = `${Object.values(query).join('&')}`;
            let queueKey = `system_vue_entity_load-${queryString}`;

            // We use a queue manager to avoid multiple request of the same item.
            this.app.queue.add(queueKey, () => {
              // Re check is any queue loaded request.
              if (buildMissing()) {
                // Load vue components before displaying list items.
                this.app.adaptive.get(
                  this.app.routing.path('system_vue_entity_load') +
                  `?${queryString}`,
                  {},
                  (response) => {
                    response.assets.js.forEach((js) => {
                      let split = js.group.split('.');
                      if (split[0] === 'vue') {
                        this.app.vue.initComponent(split[1]);
                      }
                    });

                    complete && complete(true);
                    this.app.queue.next(queueKey);
                  }
                );
              } else {
                complete && complete(true);
                this.app.queue.next(queueKey);
              }
            });

            return true;
          } else {
            complete && complete(false);

            return false;
          }
        },
      },
    },
  },
};
