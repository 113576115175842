export default class {
  app: any;
  el: any;
  form: any;
  name: string;
  translations: any;
  vars: any;

  static getPageLevelMixins() {
    return {};
  }

  constructor(app, data) {
    this.app = app;

    if (!data.el) {
      this.app.prompt.toastError('page_message.error.page_missing_el');
    }

    this.app.page.registry[data.name] = this;

    if (data.main) {
      this.app.pageMain = this;
    }

    Object.assign(this, {
      actionButtons: this.getActionButtons(),
      components: {},
      data: data,
      el: data.el,
      name: data.name,
      translations: {
        catalog: {},
      },
      vars: {},
    });

    this.init(data);
  }

  getActionButtons() {
    return {};
  }

  loadData(data) {
    // Save a reference allow to find page object for external events.
    data.instance = this;

    data.vars && Object.assign(this.vars, data.vars);

    this.app.mix(this, 'page', true);

    if (data.body) {
      this.el.innerHTML = data.body;
    }

    if (data.trans) {
      if (data.trans.domain) {
        this.translations.domains = { page: data.trans.domain };
      }

      Object.assign(this.translations.catalog, data.trans.catalog);
    }

    if (data.components) {
      // Assign page to all components.
      data.components.forEach((data) => {
        Object.assign(data, { page: this });
      });
      this.app.components.addToCatalog(data.components);
    }

    this.app.components.initAll(this.el, 'page', this.name);

    if (data.forms) {
      for (let formName in data.forms) {
        if (this.form.forms[formName]) {
          this.form.forms[formName].adaptiveResponse(data.forms[formName]);
        }
      }
    }
  }

  init(data) {
    this.loadData(data);

    this.app.mixin.invokeUntilComplete('init', 'page', [data]);
  }

  entityMainReload() {
    if (this.vars.entityMain) {
      this.app.entity.getV1(
        this.vars.entityMain.entityType,
        this.vars.entityMain.id,
        (entity) => {
          this.vars.entityMain = entity;
          this.app.adaptive.triggerEvent(
            { name: 'entity-main-update' },
            entity
          );
        }
      );
    }
  }
}
