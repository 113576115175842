export default {
  name: 'form',

  hooks: {
    app: {
      loadAppData(data) {
        if (data.forms) {
          for (let formName in data.forms) {
            if (this.form.forms[formName]) {
              this.form.forms[formName].adaptiveResponse(data.forms[formName]);
            }
          }
        }
      },
    },
  },

  methods: {
    app: {
      forms: {},

      /**
       * form.submit() method does not trigger event.
       * These event may be used by components to execute action (eg ajax forms).
       *
       * @see https://stackoverflow.com/questions/645555/should-jquerys-form-submit-not-trigger-onsubmit-within-the-form-tag
       * @param form
       * @param submitButtonFieldName
       */
      submitWithEvent(form, submitButtonFieldName = null) {
        if (submitButtonFieldName) {
          form.querySelector(`[name=${form.name}\\[${submitButtonFieldName}\\]]`).click();
        }
        else {
          // get the form element's document to create the input control with
          // (this way will work across windows in IE8).
          let button = form.ownerDocument.createElement('input');
          // make sure it can't be seen/disrupts layout (even momentarily).
          button.style.display = 'none';
          // make it such that it will invoke submit if clicked.
          button.type = 'submit';

          // append it and click it.
          form.appendChild(button).click();
          // if it was prevented, make sure we don't get a build up of buttons.
          form.removeChild(button);
        }
      },

      fileIsType(file, type) {
        if (file.type !== type) {
          this.errors.system('page_message.upload_file_type_wrong', {
            ':type': type,
          });
          return false;
        }
        return true;
      },

      isFieldEmpty(field) {
        return !field.value.trim();
      },

      getFields(form) {
        return [...form.elements];
      },

      submit(form, complete) {
        let formData = new FormData(form);
        let location = window.location;

        this.ajax.send({
          url:
            form.getAttribute('action') ||
            location.pathname + (location.hash ? '#' + location.hash : ''),
          method: 'POST',
          data: formData,
          callback: (data) => complete(JSON.parse(data)),
        });
      },
    },
  },
};
