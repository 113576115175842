export default {
  name: 'number',

  methods: {
    app: {
      currencyDefault: 'EUR',

      formatCurrency(value) {
        return value === this.number.currencyDefault ? '€' : value;
      },

      formatWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      },

      formatPrice(price, currency = this.number.currencyDefault) {
        return (
          this.number.formatWithSpaces(this.number.getFloatData(price)) +
          ' ' +
          this.number.formatCurrency(currency)
        );
      },

      parse(value) {
        if (typeof value === 'string') {
          value = this.string.replaceAll(value, ' ', '');
          return parseFloat(this.string.replaceAll(value, ',', '.'));
        }

        return value;
      },

      getIntData(value) {
        return Math.round(parseFloat(this.number.parse(value)) * 100);
      },

      getFloatData(value) {
        return parseInt(value) / 100;
      },
    },

    vue: {
      filters: {
        price: function () {
          return window['app'].number.formatPrice.apply(window['app'], arguments);
        },
      },
      methods: {
        price: function () {
          return this.app.number.formatPrice.apply(this.app, arguments);
        },
      },
    },
  },
};
