export default {
  name: 'event',

  methods: {
    app: {
      forget(name, callback, el = window.document) {
        el.removeEventListener(name, callback);
      },

      listen(name, callback, el = window.document) {
        el.addEventListener(name, callback);
      },

      trigger(name, data, el = window.document) {
        el.dispatchEvent(
          new CustomEvent(name, {
            detail: data,
          })
        );
      },
    },
  },
};
