export default {
  name: 'user',

  methods: {
    app: {
      get(path, data) {
        return this.entity.get('user', window['appData'].user.id, path, data);
      },
      post(path, data) {
        return this.entity.post('user', window['appData'].user.id, path, data);
      },
      isGranted(role, user = window['appData'].user) {
        return user.roles.indexOf(role) !== -1;
      },
    },
  },
};
