export default {
  name: 'queue',

  methods: {
    app: {
      queues: {},

      add(queueName, command, start = true) {
        this.queue.queues[queueName] =
          this.queue.queues[queueName]
          || {
            started: false,
            items: []
          };

        this.queue.queues[queueName].items.push(command);

        if (start) {
          this.queue.start(queueName);
        }
      },

      start(queueName) {
        if (this.queue.queues[queueName]
          && !this.queue.queues[queueName].started) {
          this.queue.queues[queueName].started = true;

          setTimeout(() => {
            this.queue.next(queueName);
          })
        }
      },

      stop(queueName) {
        if (this.queue.queues[queueName]
          && this.queue.queues[queueName].started) {
          delete this.queue.queues[queueName];
        }
      },

      next(queueName) {
        if (this.queue.queues[queueName]) {
          if (this.queue.queues[queueName].items.length) {
            (this.queue.queues[queueName].items.shift())();
          } else {
            this.queue.stop(queueName);
          }
        }
      }
    },
  },
};
