export default {
  name: 'string',

  methods: {
    app: {
      capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },

      /**
       * PHP printf equivalent.
       */
      format(text, args) {
        Object.entries(args).forEach((data) => {
          let reg = new RegExp(data[0], 'g');
          text = text.replace(reg, data[1]);
        });

        return text;
      },

      splitAndTrim(str, separator) {
        return str.split(separator).map(function (item) {
          return item.trim();
        });
      },

      slugify(str) {
        str = str.toLowerCase();

        let map = {
          '-': ' |_',
          a: 'á|à|ã|â|À|Á|Ã|Â',
          e: 'é|è|ê|É|È|Ê',
          i: 'í|ì|î|Í|Ì|Î',
          o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
          u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
          c: 'ç|Ç',
          n: 'ñ|Ñ',
        };

        for (let pattern in map) {
          str = str.replace(new RegExp(map[pattern], 'g'), pattern);
        }

        return str;
      },

      parseIfBoolean(string) {
        if (['false', 'true'].indexOf(string) !== -1) {
          return this.string.parseBoolean(string);
        }
        return string;
      },

      parseBoolean(string) {
        return string === 'true' || string === true;
      },

      toCamel(s) {
        return s.replace(/([\_\-]\w)/g, (m) => m[1].toUpperCase());
      },

      toKebab(s) {
        return s.replace(/[\_\-]/g, '-').toLowerCase();
      },

      toClassName(s) {
        return this.string.capitalize(this.string.toCamel(s));
      },

      replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
      },

      wrapUrl(url) {
        return `url("${url}")`;
      },

      copyToClipboard(text) {
        let area = document.createElement('textarea') as any;
        area.style.opacity = area.style.height = area.style.width = 0;
        document.body.appendChild(area);
        area.value = text;
        area.select();
        document.execCommand('copy');
        document.body.removeChild(area);
      },
    },
  },
};
