import { deleteItem as arrayDeleteItem } from "../helpers/ArrayHelper";

export default {
  name: 'prompt',

  methods: {
    app: {
      beforePageCloseCallbacks: [],

      alert(message, params) {
        // We should replace it by a nicer dialog box.
        return alert(this.locale.trans(message, params));
      },

      beforePageClose(callback) {
        this.prompt.beforePageCloseCallbacks.push(callback);

        if (this.prompt.beforePageCloseCallbacks.length === 1) {
          // Create proxy method.
          this.prompt.onWindowCloseProxy =
            this.prompt.onWindowCloseProxy ||
            this.prompt.onWindowClose.bind(this);
          window.addEventListener(
            'beforeunload',
            this.prompt.onWindowCloseProxy
          );
        }
      },

      beforePageCloseDelete(callback) {
        this.prompt.beforePageCloseCallbacks = arrayDeleteItem(
          this.prompt.beforePageCloseCallbacks,
          callback
        );

        // No more event
        if (!this.prompt.beforePageCloseCallbacks.length) {
          window.removeEventListener(
            'beforeunload',
            this.prompt.onWindowCloseProxy
          );
        }
      },

      onWindowClose(e) {
        for (let callback of this.prompt.beforePageCloseCallbacks) {
          let question = callback();
          if (typeof question === 'string') {
            e.preventDefault();
            e.returnValue = question;

            return question;
          }
        }

        delete e['returnValue'];
      },

      confirm(message, accept = () => {}, refuse = () => {}) {
        confirm(message) ? accept() : refuse();
      },

      toast(message, type = 'info', args = {}) {
        window['M'].toast({
          html: this.locale.trans(message, args) || '',
          classes: 'rounded ' + type,
        });
      },

      toastError(message, args = {}, debugData = {}) {
        this.prompt.toast(message, 'error', args);

        Object.assign(debugData, {
          message: message,
          args: args,
        });
        console.error(debugData);
      },

      toastSuccess(message, args = {}) {
        this.prompt.toast(message, 'success', args);
      },

      keyPressIsEnter(e) {
        return e.key === 'Enter' || e.keyCode === 13;
      },
    },
    page: {
      message(message, type = 'info', args = {}) {
        this.app.prompt.toast(this.trans(message, args), type);
      },

      messageError(message, args = {}) {
        this.app.prompt.toastError(this.trans(message, args));
      },

      messageSuccess(message, args = {}) {
        this.app.prompt.toastSuccess(this.trans(message, args));
      },
    },
  },
};
