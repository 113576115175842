import { deleteItem as arrayDeleteItem } from "../helpers/ArrayHelper";

export default {
  name: 'adaptive',

  hooks: {
    app: {
      loadAppData(data, registry) {
        // Expect all assets to be loaded before triggering events.
        if (registry.ComponentsService === 'complete') {
          for (let event of data.events) {
            this.adaptive.triggerEvent(event, data);
          }

          return 'complete';
        }
        return 'wait';
      },
    },
  },

  methods: {
    app: {
      eventListeners: {},
      forms: {},

      routeGet(route, options, data, callback, extra) {
        return this.adaptive.get(
          this.routing.path(route, options),
          data,
          callback,
          extra
        );
      },

      get(path, options: any = {query: {}}, callback) {
        this.ajax.getJSON(path, options.query, (data) => {
          options.parseData && options.parseData(data);

          this.loadAppData(data, callback);
        });
      },

      addEventListener(eventName, callback) {
        this.adaptive.eventListeners[eventName] =
          this.adaptive.eventListeners[eventName] || [];
        this.adaptive.eventListeners[eventName].push(callback);
      },

      removeEventListener(eventName, callback) {
        arrayDeleteItem(
          this.adaptive.eventListeners[eventName],
          callback
        );
        // Cleanup.
        if (!this.adaptive.eventListeners[eventName].length) {
          delete this.adaptive.eventListeners[eventName];
        }
      },

      triggerEvent(event, data) {
        // Trigger event.
        if (this.adaptive.eventListeners[event.name]) {
          for (let callback of this.adaptive.eventListeners[event.name]) {
            callback(event, data);
          }
        }
      },
    },
  },
};
