import 'materialize-css/dist/js/materialize.min.js';
import '@fortawesome/fontawesome-free';
import Page from './Page';

import AdaptiveService from '../services/AdaptiveService';
import AjaxService from '../services/AjaxService';
import AnimationService from '../services/AnimationService';
import ApiService from '../services/ApiService';
import AssetsService from '../services/AssetsService';
import ComponentsService from '../services/ComponentsService';
import DateService from '../services/DateService';
import LazyService from '../services/LazyService';
import EntityService from '../services/EntityService';
import ErrorsService from '../services/ErrorsService';
import EventsService from '../services/EventsService';
import FormService from '../services/FormService';
import LocaleService from '../services/LocaleService';
import LocationService from '../services/LocationService';
import MixinsService from '../services/MixinsService';
import ModalService from '../services/ModalsService';
import NumberService from '../services/NumberService';
import ObjectService from '../services/ObjectService';
import PagesService from '../services/PagesService';
import PromptService from '../services/PromptsService';
import QueueService from '../services/QueuesService';
import ResponsiveService from '../services/ResponsiveService';
import RoutingService from '../services/RoutingService';
import StringService from '../services/StringService';
import UserService from '../services/UserService';
import VueService from '../services/VueService';

import LayoutInterface from '../interfaces/RenderData/LayoutInterface';
import AsyncConstructor from './AsyncConstructor';

export default class extends AsyncConstructor {
  public hasCoreLoaded: boolean = false;
  isReady: any;
  public lib: object = {};
  mixin: any;
  mixins: any;
  readyCallback: any;
  readyCallbacks: any;

  constructor(readyCallback?: any | Function, globalName = 'app') {
    super();

    window[globalName] = this;

    // Allow callback as object definition.
    if (typeof readyCallback === 'object') {
      Object.assign(this, readyCallback);
      // Allow object.readyCallback property.
      readyCallback = readyCallback.readyCallback || readyCallback;
    }

    Object.assign(this, {
      classesDefinitions: {},
      debug: true,
      bootJsBuffer: [],
      isReady: false,
      mixins: null,
      readyCallbacks: [],
    });

    // Allow callback as object definition.
    if (typeof readyCallback === 'object') {
      Object.assign(this, readyCallback);
      // Allow object.readyCallback property.
      readyCallback = this.readyCallback || readyCallback;
    }  
    
    let doc = window.document;

    let run = async () => {
      // Run mixins after page loads as it might
      // depends on env variables from window['appData'].
      this.mixins = this.getMixinAndDependencies(this.getMixins());

      this.mix(this, 'app', true, true);

      // Init mixins.
      this.mixin.invokeUntilComplete('init', 'app', [], async () => {
      window['appData'].page.el = document.getElementById('main');

      let registry: {
        bundles: any;
        layoutRenderData: LayoutInterface;
      } = window['appRegistry'];

      this.addLibraries(this.lib);

      // The main functionalities are ready,
      // but first data has not been loaded.
      this.hasCoreLoaded = true;

      // Load template data.
      this.loadAppData(window['appData'], async () => {

      // Display page content.
          document.getElementById('main').classList.remove('loading');
	  
      // Execute ready callbacks.
      await this.readyComplete();

      readyCallback && (await readyCallback());
        });
      });
    };

    let readyState = doc.readyState;

    // Document has been parsed.
    // Allows running after loaded event.
    if (['complete', 'loaded', 'interactive'].indexOf(readyState) !== -1) {
      this.async(run);
    } else {
      doc.addEventListener('DOMContentLoaded', run);
    }
  }

  ready(callback) { 
    if (this.isReady) {
      callback();
    } else {
      this.readyCallbacks.push(callback);
    }
  }

  loadAppData(data, complete) {
    if (data.reload) {
      document.location.reload();
      return;
    }

    if (data.redirect) {
      document.location.replace(data.redirect.url);
      return;
    }

    // Use mixins hooks.
    this.mixin.invokeUntilComplete('loadAppData', 'app', [data], () => {
      complete && complete(data);
    });
  }

  getClassPage() {
    return Page;
  }

  getMixins() {
    return {
      ...{
        AdaptiveService,
        AjaxService,
        AnimationService,
        ApiService,
        AssetsService,
        ComponentsService,
        DateService,
        EntityService,
        ErrorsService,
        EventsService,
        FormService,
        LazyService,
        LocaleService,
        LocationService,
        MixinsService,
        ModalService,
        NumberService,
        ObjectService,
        PagesService,
        PromptService,
        QueueService,
        ResponsiveService,
        RoutingService,
        StringService,
        UserService,
        VueService,
      },
      // Append page specific mixins.
      ...(window['pageCurrent'] || this.getClassPage()).getPageLevelMixins(),
    };
  }

  getMixinAndDependencies(mixins) {
    for (let mixin of Object.values(mixins)) {
      if (mixin['dependencies']) {
        let dependencies = mixin['dependencies'];
        this.getMixinAndDependencies(dependencies);
        mixins = {
          ...mixins,
          ...dependencies,
        };
      }
    }

    return mixins;
  }

  mix(parentDest, group, bindContext = false, nested = false) {
    Object.values(this.mixins).forEach((mixin) => {
      if (mixin['methods'] && mixin['methods'][group]) {
        let dest;
        let toMix = mixin['methods'][group];

        if (nested) {
          dest = {};
          parentDest[mixin['name']] = dest;
        } else {
          dest = parentDest;
        }

        // Use a "one level deep merge" to allow mix groups of methods.
        for (let i in toMix) {
          let value = toMix[i];

          // Mix objects.
          if (value && value.constructor && value.constructor === Object) {
            dest[i] = dest[i] || {};

            Object.assign(dest[i], toMix[i]);
          }
          // Methods, bind it to main object.
          else if (bindContext && typeof value === 'function') {
            dest[i] = toMix[i].bind(parentDest);
          }
          // Override others.
          else {
            dest[i] = toMix[i];
          }
        }
      }
    });
  }

  /**
   * @param classRegistryName
   */
  getClassDefinition(registryGroup, baseClassDefinition, classRegistryName) {
    let key = `${registryGroup}.${classRegistryName}`;

    if (!this['classesDefinitions'][key]) {
      let classDefinition = baseClassDefinition;
      let extraDefinition =
        window['appRegistry'][registryGroup].classes[classRegistryName];

      if (extraDefinition) {
        classDefinition = class extends baseClassDefinition {
        };

        Object.assign(classDefinition.prototype, extraDefinition);
        Object.assign(classDefinition, extraDefinition.static);
      }

      this['classesDefinitions'][key] = classDefinition;
    }

    return this['classesDefinitions'][key];
  }

  addLib(name: string, object: any) {
    this.lib[name] = object;
  }

  addLibraries(libraries) {
    // Initialize preexisting libs.
    Object.entries(libraries).forEach((data) => {
      this.addLib(data[0], data[1]);
    });
  }
}
